import { BASE_URL_PRODUCCION } from "../utils/Constants";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { modalCargando } from "./ui/sweetAlerts";

const IngresoIntranet = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const obtenerPerfiles = () => {
    // Decodifica la informacion que mantiene le token
    const data = jwt_decode(token);

    // Si el token mantiene un perfil activo este lo redirecciona automaticamente
    if (data.total_perfiles === 1) {
      // estos seran los datos que guardaras
      localStorage.setItem('usuarioNombre', data.nombre)
      localStorage.setItem('perfil', data.nombre_perfil)
      localStorage.setItem('token', token)
      localStorage.setItem('id_perfil', data.id_perfil)
      localStorage.setItem('id_usuario_sap', data.id_usuario_sap)
      modalCargando("Ingresando...");
      redireccion();
    }
    // si mantiene mas de 1 perfil tiene la opcion de con cual desea ingresar
    if (data['total_perfiles'] >= 2) {
      Swal.fire({
        title: 'Por favor seleccione el perfil con el que desea ingresar',
        icon: 'info',
        showConfirmButton: data['MOES_NRO_EJECUTIVO_RRHH'],
        confirmButtonColor: "#0d6efd",
        showDenyButton: data['MOES_JZ'],
        // denyButtonColor:"#198754",
        confirmButtonText: "R.R.H.H",
        denyButtonText: "Jefe de Zona",
        cancelButtonText: "Administrador",
        showCancelButton: data['MOES_ADMINISTRADOR'],
        cancelButtonColor: "#ffc414",
        closeOnClickOutside: false,
        reverseButtons: false, // orden botones al reves
        customClass: {
          container: 'containerMensajeSimple',
          title: 'titleMensajeSimple',
          text: 'txtLabellInput',
          popup: `popUpSweetCancelarEnviar`,
          confirmButton: 'btnRRHH btnSweetModal',
          cancelButton: 'btnAdmin btnSweetModal',
          denyButton: 'btnRRHH btnSweetModal',
          actions: 'd-flex w-100 justify-content-evenly px-5'
        },
        backdrop: '#395c9d5c left top no-repeat',
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        modalCargando("Ingresando...");

        if (result.isConfirmed) {// selecciona RRHH
          obtenerTokenPerfilUnico(3)
        } else if (result.isDenied) { // selecciona Jefe de zona
          obtenerTokenPerfilUnico(2)
        } else { // selecciona Administrador
          obtenerTokenPerfilUnico(4)
        }
      })
    }
  }

  const redireccion = () => {
    Swal.close();
    const perfil = localStorage.getItem('id_perfil');
    switch (perfil) {
      case "6":
        return navigate(`/monitor`); // Si el perfil es 6 sera redireccionado a la vista monitor
      default:
        return navigate(`/`);  // Ruta por defecto si ningún caso coincide
    }
  }


  const obtenerTokenPerfilUnico = async (idPerfil) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${BASE_URL_PRODUCCION}/obtenertoken?inPerfil=${idPerfil}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          const token = result.token
          const nombreUsuario = result.data_usuario.nombre
          const nombrePerfil = result.data_usuario.nombre_perfil
          const idPerfil = result.data_usuario.id_perfil
          localStorage.setItem('usuarioNombre', nombreUsuario)
          localStorage.setItem('perfil', nombrePerfil)
          localStorage.setItem('token', token)
          localStorage.setItem('id_perfil', idPerfil)

          redireccion()
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    obtenerPerfiles();
  }, []);

  return (
    <></>
  );
};

export default IngresoIntranet;
