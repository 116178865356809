import React, { useState, useEffect, useContext } from "react";
import { guardarDatosPersonalesApi } from "../api/contratacion/datosPersonalesApi";
import { guardarDatosPrevisionApi } from "../api/contratacion/datosPrevisionApi";
import { guardarDatosBancariosApi } from "../api/contratacion/datosBancariosApi";
import { guardarDatosLaboralesApi } from "../api/contratacion/datosLaboralesApi";
import { guardarDireccionApi } from "../api/contratacion/datosDireccionApi";
import FormDatosPersonales from "./procesoContratacion/FormDatosPersonales";
import FormDatosPrevision from "./procesoContratacion/FormDatosPrevision";
import FormDatosBancarios from "./procesoContratacion/FormDatosBancarios";
import FormDatosDireccion from "./procesoContratacion/FormDatosDireccion";
import FormDatosLaborales from "./procesoContratacion/FormDatosLaborales";
import { guardarDatosSaludApi } from "../api/contratacion/datosSaludApi";
import { guardarDocumentoApi } from "../api/contratacion/documentosApi";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import FormDocumentos from "./procesoContratacion/FormDocumentos";
import { txtMensajeDatosIngresados } from "../utils/Constants.js";
import { DataContext } from "../contextData/contextData";
import { DOMINIOS_VALIDOS } from "../utils/Constants.js";
import { BACKDROP } from '../utils/Constants.js';
import { DOCUMENTOS } from "../utils/Constants";
import { SpinnerModal } from "./ui/Spinner";
import { getUno, post } from "../api/apis";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";
import {
  aprobarSolicitudVacanteApi,
  guardarVacanteApi,
  guardadoParcialApi,
} from "../api/contratacion/solicitudVacanteApi";
import {
  modalDeclinarSolicitud,
  modalDeclinarProceso,
  mensajeSimple,
  modalCargando,
  modalEjecutar,
} from "./ui/sweetAlerts";
import { guardarDatosPersonaExtranjeraApi } from "../api/contratacion/datosPersonaExtranjeraApi";
import { getBusinessDaysAgo, momentFecha } from "../utils/funciones.js";

const HtmlList = ({ lista }) => {
  return (
    <>
      <label className="h3 text-center mt-3">Guardando datos</label>
      <div>
        {
          lista.map((itm, indx) => {
            return (
              <div className="border rounded-3 py-1 px-2 d-flex justify-content-between mx-3 my-2 align-items-center" key={indx}>
                <span>{itm.texto}</span>
                <img
                  src={require(`../../public/icon/${itm.icono}`)}
                  width="17"
                  height="17"
                  style={{ marginTop: '3px' }}
                />
              </div>
            )
          })
        }
      </div>
    </>
  )
}

const FormContratacionScreen = () => {
  const { state } = useLocation();

  const { page, setearPagina } = useContext(DataContext);
  const [listaGuardado, setListaGuardado] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleSpinner, setVisibleSpinner] = useState(true);
  const [txtSpinner, setTxtSpinner] = useState('');
  const { idPersona } = useParams();
  const [bloqueado, setBloqueado] = useState(false);
  const navigate = useNavigate();
  const msjCorrecto = ", guardado correctamente."
  const msjIncorrecto = ", no se pudo guardar."
  const fechaLaboralMinima = getBusinessDaysAgo(5);
  const fechaMaximaIngreso = moment().add(6, 'months');
  const FormTitles = [
    "Datos Laborales",
    "Datos Personales",
    "Datos de dirección",
    "Datos de previsión",
    "Datos bancarios",
    "Documentos / Soporte de contratacion",
  ];

  function setearMensaje(tipo, resp) {
    setListaGuardado(
      (listaGuardado) => [
        ...listaGuardado,
        {
          icono: `${resp.status === 'success' ? 'check.png' : 'warning.png'}`,
          texto: `${tipo}${resp.status === 'success' ? msjCorrecto : msjIncorrecto}`,
          Tooltip: `${resp.status === 'info' ? resp.message : ''}`
        }
      ]
    );
  }

  async function getUnaPersona(id) {
    const respPersona = await getUno('persona', { idPersona: id });
    const {
      apellido_mat,
      apellido_pat,
      correo,
      fecha_nacimiento,
      // id,
      id_educacion_nivel,
      id_persona_estado_civil,
      id_persona_genero,
      identificacion,
      nombre
    } = respPersona.data.data;

    formik.setValues({
      ...formik.values, datosPersonales:
      {
        apellidoMaterno: apellido_mat,
        apellidoPaterno: apellido_pat,
        email: correo,
        estadoCivil: id_persona_estado_civil,
        sexo: id_persona_genero,
        nombres: nombre,
        nivelFormacion: id_educacion_nivel || '',
        rut: identificacion,
        fechaNacimiento: fecha_nacimiento !== 'None' ? fecha_nacimiento : '',
        telefonoCelular: ''
      }
    });
  }

  useEffect(() => {
    if (idPersona) {
      getUnaPersona(idPersona);
    }
  }, []);

  // // #region FUNCION GUARDADO DATOS ANTIGUA (>>>>ESTO SOLO LO BORRA DANIEL<<<<)
  // const saveNewDataFrom = async (values, personaSolicitante = false, editaVacante = true) => {
  //   const valuesPersonales = { ...values.datosPersonales, rut: localStorage.getItem('rut') }
  //   setVisibleModal(!visibleModal);
  //   setTxtSpinner(() => setTxtSpinner('Datos personales'));

  //   let resp = await guardarDatosPersonalesApi(valuesPersonales);

  //   setearMensaje('Datos personales', resp);
  //   const idPersona = resp.data.id_persona;

  //   if (values.datosPersonales.nacionalidad != '1') {
  //     setTxtSpinner(() => setTxtSpinner('Datos persona extranjera'));
  //     const respExtranjero = await guardarDatosPersonaExtranjeraApi(values.datosPersonaExtranjera, idPersona);
  //     setearMensaje('Datos persona extranjera', respExtranjero);
  //   }

  //   setTxtSpinner(() => setTxtSpinner('Datos bancarios'));
  //   resp = await guardarDatosBancariosApi(values.datosBancarios, idPersona);
  //   setearMensaje('Datos bancarios', resp);

  //   setTxtSpinner(() => setTxtSpinner('Datos previsión'));
  //   resp = await guardarDatosPrevisionApi(values.datosPrevision, idPersona);
  //   setearMensaje('Datos previsión', resp);

  //   setTxtSpinner(() => setTxtSpinner('Datos salud'));
  //   resp = await guardarDatosSaludApi(values.datosPrevision, idPersona);
  //   setearMensaje('Datos salud', resp);

  //   setTxtSpinner(() => setTxtSpinner('Datos dirección'));
  //   resp = await guardarDireccionApi(values.datosDireccion, idPersona);
  //   setearMensaje('Datos dirección', resp);

  //   await Promise.all(documentos.map(async (dataDocumento) => {
  //     if (values.datosDocumentos[dataDocumento.documento] !== null) {
  //       setTxtSpinner(() => setTxtSpinner('Documento'));
  //       const respDocumento = await guardarDocumentoApi(values.datosDocumentos[dataDocumento.documento], idPersona, dataDocumento['id_tipo'])
  //       setearMensaje('Documento', respDocumento);
  //     }
  //   }));

  //   if (personaSolicitante && editaVacante) {
  //     let ingresarDatosLAborales = false;

  //     if (!personaSolicitante.idSolicitud) {
  //       setTxtSpinner(() => setTxtSpinner('Solicitud'));
  //       var respVacante = await guardarVacanteApi(idPersona, 1, page);
  //       setearMensaje('Solicitud', respVacante);
  //       if (respVacante.status === 'success') { ingresarDatosLAborales = true; }
  //     }

  //     if (ingresarDatosLAborales || personaSolicitante.idSolicitud) {
  //       const id_solicitud = personaSolicitante.idSolicitud ? personaSolicitante.idSolicitud : respVacante.data.id_solicitud;
  //       setTxtSpinner(() => setTxtSpinner('Datos laborales'));
  //       const respDatosLaborales = await guardarDatosLaboralesApi(values.datosLaborales, id_solicitud);
  //       setearMensaje('Datos laborales', respDatosLaborales);

  //       if (personaSolicitante.idSolicitud) {
  //         setTxtSpinner(() => setTxtSpinner('Vacante'));
  //         const respSolicitante = await aprobarSolicitudVacanteApi(id_solicitud);
  //         setearMensaje('Vacante', respSolicitante);
  //       }

  //       if (respDatosLaborales.status === "success") { setTimeout(() => { window.location = "/" }, 300000); }
  //     }
  //   }

  //   setVisibleSpinner(!visibleSpinner);
  //   localStorage.removeItem('rut');
  // }
  // // #endregion

  const saveNewDataFrom2 = async (values, personaSolicitante = false, editaVacante = true) => {
    const valuesPersonales = { ...values.datosPersonales, rut: localStorage.getItem('rut') }
    setVisibleModal(!visibleModal);

    setTxtSpinner(() => setTxtSpinner('Datos personales'));
    let resp = await guardarDatosPersonalesApi(valuesPersonales);
    setearMensaje('Datos personales', resp);

    const idPersona = resp.data.id_persona;

    if (values.datosPersonales.nacionalidad != '1') {
      setTxtSpinner(() => setTxtSpinner('Datos persona extranjera'));
      const respExtranjero = await guardarDatosPersonaExtranjeraApi(values.datosPersonaExtranjera, idPersona);
      setearMensaje('Datos persona extranjera', respExtranjero);
    }
    if (page >= 2) {
      setTxtSpinner(() => setTxtSpinner('Datos dirección'));
      resp = await guardarDireccionApi(values.datosDireccion, idPersona);
      setearMensaje('Datos dirección', resp);
    }
    if (page >= 3) {
      setTxtSpinner(() => setTxtSpinner('Datos previsión'));
      resp = await guardarDatosPrevisionApi(values.datosPrevision, idPersona);
      setearMensaje('Datos previsión', resp);

      setTxtSpinner(() => setTxtSpinner('Datos salud'));
      resp = await guardarDatosSaludApi(values.datosPrevision, idPersona);
      setearMensaje('Datos salud', resp);
    }
    if (page >= 4) {
      setTxtSpinner(() => setTxtSpinner('Datos bancarios'));
      resp = await guardarDatosBancariosApi(values.datosBancarios, idPersona);
      setearMensaje('Datos bancarios', resp);
    }
    if (page === 5) {
      await Promise.all(DOCUMENTOS.map(async (dataDocumento) => {
        if (values.datosDocumentos[dataDocumento.documento] !== null) {
          setTxtSpinner(() => setTxtSpinner('Documento'));
          const respDocumento = await guardarDocumentoApi(values.datosDocumentos[dataDocumento.documento], idPersona, dataDocumento['id_tipo'])
          setearMensaje('Documento', respDocumento);
        }
      }));
    }
    if (personaSolicitante && editaVacante) {
      let ingresarDatosLAborales = false;

      if (!personaSolicitante.idSolicitud) {
        setTxtSpinner(() => setTxtSpinner('Solicitud'));

        if ((page < FormTitles.length - 1) && (page > 0)) {
          var respVacante = await guardadoParcialApi(idPersona, 1, page);
        }else{
          var respVacante = await guardarVacanteApi(idPersona, 1, page);
        }
        setearMensaje('Solicitud', respVacante);
        if (respVacante.status === 'success') { ingresarDatosLAborales = true; }
      }

      if (ingresarDatosLAborales || personaSolicitante.idSolicitud) {
        const id_solicitud = personaSolicitante.idSolicitud ? personaSolicitante.idSolicitud : respVacante.data.id_solicitud;
        setTxtSpinner(() => setTxtSpinner('Datos laborales'));
        const respDatosLaborales = await guardarDatosLaboralesApi(values.datosLaborales, id_solicitud);
        setearMensaje('Datos laborales', respDatosLaborales);

        if (personaSolicitante.idSolicitud && page === 5) {
          setTxtSpinner(() => setTxtSpinner('Vacante'));
          const respSolicitante = await aprobarSolicitudVacanteApi(id_solicitud);
          setearMensaje('Vacante', respSolicitante);
        }

        if (respDatosLaborales.status === "success") { setTimeout(() => { window.location = "/" }, 300000); }
      }
    }
    setVisibleSpinner(!visibleSpinner);
    localStorage.removeItem('rut');
  }





  const PageDisplay = () => {
    if (page === 0) { return <FormDatosLaborales bloqueado={bloqueado} formik={formik} />; }
    if (page === 1) { return <FormDatosPersonales formik={formik} />; }
    if (page === 2) { return <FormDatosDireccion formik={formik} />; }
    if (page === 3) { return <FormDatosPrevision formik={formik} />; }
    if (page === 4) { return <FormDatosBancarios formik={formik} />; }
    if (page === 5) { return <FormDocumentos formik={formik} />; }
  }

  const validate = (values) => {
    let errors = {
      datosLaborales: {},
      datosPersonales: {},
      datosPersonaExtranjera: {},
      datosBancarios: {},
      datosDireccion: {},
      datosPrevision: {},
      datosDocumentos: {},
    };
    // datos laborales
    const {
      fechaIngreso,
      cargo,
      planHorario,
      tipoContrato,
      tieneTemporis,
      unidadOrganizativaRolTemporis,
    } = values.datosLaborales;

    if (!fechaIngreso) {
      errors.datosLaborales.fechaIngreso = "Requerido";
    }
    if (moment(fechaIngreso).format('YYYY-MM-DD') < fechaLaboralMinima.format('YYYY-MM-DD') ) {
      errors.datosLaborales.fechaIngreso = "No se puede ingresar una fecha con más de 5 días de retraso";
    }
    if (moment(fechaIngreso).format('YYYY-MM-DD') > fechaMaximaIngreso.format('YYYY-MM-DD') ) {
      errors.datosLaborales.fechaIngreso = "Fecha de ingreso no puede superar los 6 meses futuros";
    }
    if (!cargo) {
      errors.datosLaborales.cargo = "Requerido";
    }
    if (!planHorario) {
      errors.datosLaborales.planHorario = "Requerido";
    }
    
    if(tieneTemporis && !unidadOrganizativaRolTemporis) {
      errors.datosLaborales.unidadOrganizativaRolTemporis = "Requerido";
    }

    if (!tipoContrato) {
      errors.datosLaborales.tipoContrato = "Requerido";
    } else if (tipoContrato && !fechaIngreso) {
      errors.datosLaborales.tipoContrato = "Es necesario la fecha de ingreso";
    }


    // datos personales
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      fechaNacimiento,
      sexo,
      nacionalidad,
      email,
      telefonoCelular,
      estadoCivil
    } = values.datosPersonales;

    if (!nombres) {
      errors.datosPersonales.nombres = "Requerido";
    }

    if (!apellidoPaterno) {
      errors.datosPersonales.apellidoPaterno = "Requerido";
    }
    if (!estadoCivil) {
      errors.datosPersonales.estadoCivil = "Requerido";
    }

    if (!fechaNacimiento) {
      errors.datosPersonales.fechaNacimiento = "Requerido";
    }

    if (moment(fechaIngreso).diff(moment(fechaNacimiento), "years") < 18) {
      errors.datosPersonales.fechaNacimiento = "La persona debe ser mayor de 18 años.";
    }

    if (!sexo) {
      errors.datosPersonales.sexo = "Requerido";
    }

    if (!nacionalidad) {
      errors.datosPersonales.nacionalidad = "Requerido";
    }

    if (!telefonoCelular) {
      errors.datosPersonales.telefonoCelular = "Requerido";
    }

    if (!email) {
      errors.datosPersonales.email = "Requerido";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.datosPersonales.email = "Direccion de correo invalida.";
    }
    if (DOMINIOS_VALIDOS.includes(email.split("@")[1])) {
      errors.datosPersonales.email = "El dominio del correo es inválido";
    }

    if (telefonoCelular.length > 0 && telefonoCelular.length !== 8) {
      errors.datosPersonales.telefonoCelular = "debe ingresar 8 digitos.";
    }

    //datos de personal extranjero
    const { tipoVisa, visaTemporariaDesde, visaTemporariaHasta } =
      values.datosPersonaExtranjera;

    if (nacionalidad != "1" && !tipoVisa) {
      errors.datosPersonaExtranjera.tipoVisa = "Requerido";
    }

    if (nacionalidad !== "1" && !visaTemporariaDesde) {
      errors.datosPersonaExtranjera.visaTemporariaDesde = "Requerido";
    }

    if (nacionalidad !== "1" && !visaTemporariaHasta) {
      errors.datosPersonaExtranjera.visaTemporariaHasta = "Requerido";
    }

    //datos direccion
    const { calle, numeroDepartamento, region, comuna, tipoDireccion, numeroCasa } =
      values.datosDireccion;

    if (!tipoDireccion) {
      errors.datosDireccion.tipoDireccion = "Requerido";
    }

    if (!calle) {
      errors.datosDireccion.calle = "Requerido";
    }

    if (numeroDepartamento && numeroDepartamento < 0) {
      errors.datosDireccion.numeroDepartamento = "Ingrese un numero válido";
    }

    if (!region) {
      errors.datosDireccion.region = "Requerido";
    }

    if (!comuna) {
      errors.datosDireccion.comuna = "Requerido";
    }
    
    if (!numeroCasa) {
      errors.datosDireccion.numeroCasa = "Requerido";
    }

    //datos prevision
    const { jubilado, previsionSalud, previsionSocial, planPactado } =
      values.datosPrevision;
    if (!jubilado) {
      errors.datosPrevision.jubilado = "Requerido";
    }
    if (!previsionSalud) {
      errors.datosPrevision.previsionSalud = "Requerido";
    }
    if (!previsionSocial) {
      errors.datosPrevision.previsionSocial = "Requerido";
    }

    if (previsionSalud !== "1" && !planPactado) {
      errors.datosPrevision.planPactado = "Requerido";
    }

    const regexp = /^\d+(\.\d{1,4})?$/;
    if (previsionSalud !== "1" && (planPactado === '' || planPactado === '0' || planPactado === '0,')) {
      errors.datosPrevision.planPactado = "Ingrese corretamente el valor de la UF";
    }

    //datos bancarios
    const { formaDePago, tipoCuenta, banco, numeroCuenta } =
      values.datosBancarios;
    if (!formaDePago) {
      errors.datosBancarios.formaDePago = "Requerido";
    }

    if (formaDePago === "2" && !banco) {
      errors.datosBancarios.banco = "Requerido";
    }

    if (formaDePago === "2" && !tipoCuenta) {
      errors.datosBancarios.tipoCuenta = "Requerido";
    }

    if (formaDePago === "2" && !numeroCuenta) {
      errors.datosBancarios.numeroCuenta = "Requerido";
    }

    //datos de documentos
    const {
      curriculum,
      cedula,
      antecedentes,
      residencia,
      afp,
      salud,
      visa,
      jubilacion,
      covid
    } = values.datosDocumentos;

    const { certificadoCovid } = values.datosPrevision;

    const arrTipoDocumentosGeneral = ['application/pdf', 'image/jpeg', 'image/png']
    const arrTipoDocumentoResidencial = [...arrTipoDocumentosGeneral, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

    if ((covid === null || covid.length === 0) && certificadoCovid === "1") {
      errors.datosDocumentos.covid = "Requerido";
    }

    if (curriculum === null || curriculum.length === 0) {
      errors.datosDocumentos.curriculum = "Requerido";
    }

    if (cedula === null || cedula.length === 0) {
      errors.datosDocumentos.cedula = "Requerido";
    }

    if (antecedentes === null || antecedentes === 0) {
      errors.datosDocumentos.antecedentes = "Requerido";
    }

    if (residencia === null || residencia === 0) {
      errors.datosDocumentos.residencia = "Requerido";
    }

    if (afp === null || afp === 0) {
      errors.datosDocumentos.afp = "Requerido";
    }

    if (salud === null || salud === 0) {
      errors.datosDocumentos.salud = "Requerido";
    }

    // validar certificado de visa
    if (tipoVisa === "2" && !visa) {
      errors.datosDocumentos.visa = "Requerido";
    }

    // validar certificado dejubilacion
    if (!jubilacion && jubilado === "1") {
      errors.datosDocumentos.jubilacion = "Requerido";
    }

    const noHayErrores = Object.values(errors).every(el => Object.values(el).length === 0);
    errors = noHayErrores ? formik.setErrors({}) : errors

    return errors;
  };
  const personaSolicitante = useSelector(store => store.datosPersonaSolicitante.dataPersona);
  const guardarProcesoDeContratacion = (values) => {
    saveNewDataFrom2(values, personaSolicitante);
  }

  const formik = useFormik({
    initialValues: {
      datosLaborales: {
        fechaIngreso: "",
        cargo: "",
        planHorario: "",
        unidadOrganizativaRolTemporis: "",
        cargoRolTemporis: "",
        tipoContrato: "",
        fechaFinContrato: "",
      },
      datosPersonales: {
        rut: personaSolicitante.rut,
        id_persona: personaSolicitante.data.id_persona ? personaSolicitante.data.id_persona : "",
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        sexo: "",
        estadoCivil: "",
        nivelFormacion: "",
        nacionalidad: "",
        email: "",
        telefonoCelular: "",
      },
      datosPersonaExtranjera: {
        tipoVisa: 0,
        visaTemporariaDesde: "",
        visaTemporariaHasta: "",
      },
      datosDireccion: {
        calle: "",
        numeroCasa: "",
        numeroDepartamento: "",
        region: "",
        comuna: "",
        tipoDireccion: ''
      },
      datosPrevision: {
        jubilado: "",
        previsionSalud: "",
        previsionSocial: "",
        planPactado: "",
        certificadoCovid: "",
        certificadoDiscapacidad: ""
      },
      datosBancarios: {
        formaDePago: "",
        banco: "",
        tipoCuenta: "",
        numeroCuenta: "",
      },

      datosDocumentos: {
        curriculum: null,
        cedula: null,
        antecedentes: null,
        residencia: null,
        afp: null,
        salud: null,
        visa: null,
        discapacidad: null,
        covid: null,
        jubilacion: null,
      },
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => { guardarProcesoDeContratacion(values) },
  });

  const formikTemporal = useFormik({
    enableReinitialize: true,
    initialValues: {
      datosLaborales: {
        fechaIngreso: formik.values.datosLaborales.fechaIngreso,
        cargo: formik.values.datosLaborales.cargo,
        planHorario: formik.values.datosLaborales.planHorario,
        unidadOrganizativaRolTemporis: formik.values.datosLaborales.unidadOrganizativaRolTemporis,
        tipoContrato: formik.values.datosLaborales.tipoContrato,
        fechaFinContrato: formik.values.datosLaborales.fechaFinContrato,
        tieneTemporis: false
      },
      datosPersonales: {
        rut: personaSolicitante.rut,
        id_persona: personaSolicitante.data.id_persona ? personaSolicitante.data.id_persona : "",
        nombres: formik.values.datosPersonales.nombres,
        apellidoPaterno: formik.values.datosPersonales.apellidoPaterno,
        apellidoMaterno: formik.values.datosPersonales.apellidoMaterno
      }
    },
    onSubmit: (values) => {
    },
  })

  const disabledButtonNext = () => {
    let botonDesHabilitado = true;
    if (formik.dirty && page === 0 && formik.errors.datosLaborales) {
      if (Object.keys(formik.errors.datosLaborales).length === 0) {
        return botonDesHabilitado = false
      }
    }

    if (formik.dirty && page === 1 && formik.errors.datosPersonales && formik.errors.datosPersonaExtranjera) {
      if (Object.keys(formik.errors.datosPersonales).length === 0 && Object.keys(formik.errors.datosPersonaExtranjera).length === 0) {
        return botonDesHabilitado = false
      }
    }

    if (formik.dirty && page === 2 && formik.errors.datosDireccion) {
      if (Object.keys(formik.errors.datosDireccion).length === 0) {
        return botonDesHabilitado = false
      }
    }


    if (formik.dirty && page === 3 && formik.errors.datosPrevision) {
      if (Object.keys(formik.errors.datosPrevision).length === 0) {
        return botonDesHabilitado = false
      }
    }

    if (formik.dirty && page === 4 && formik.errors.datosBancarios) {
      if (Object.keys(formik.errors.datosBancarios).length === 0) {
        return botonDesHabilitado = false
      }
    }

    if (formik.dirty && page === 5 && formik.errors.datosDocumentos) {
      if (Object.keys(formik.errors.datosDocumentos).length === 0) {
        return botonDesHabilitado = false
      }
    }

    if (formik.dirty && Object.keys(formik.errors).length === 0) { return botonDesHabilitado = false }

    return botonDesHabilitado
  };

  const avanzar = async () => {
    modalCargando('Bloqueando posición...');
    const { fechaIngreso, cargo } = formik.values.datosLaborales;
    const resp = await post('bloqueoposicion', { codPosicion: JSON.parse(cargo).MOES_COD_POSICION, fechaInicio: fechaIngreso });
    if (resp.data.status === 'info') { mensajeSimple('info', resp.data.message, 4500); }
    else if (resp.data.status === 'success') {
      mensajeSimple(resp.data.status, resp.data.message, 5000);
      setTimeout(() => {
        localStorage.setItem('page', parseInt(page) + 1);
        setearPagina((currentPage) => currentPage + 1);
        // setBloqueado(!bloqueado);
        setBloqueado(true);
      }, 5000);
    }
    else { mensajeSimple('error', 'Ha ocurrido un error.'); }
  }

  const resetValores = (arr) => {
    arr.forEach((el) => formik.setFieldValue(`datosDocumentos.${el}`, null));
  }

  const declinar = () => {
    localStorage.setItem('page', parseInt(page) - 1);
    setearPagina((currentPage) => currentPage - 1);
    resetValores([
      'curriculum',
      'cedula',
      'antecedentes',
      'residencia',
      'afp',
      'salud',
      'visa',
      'discapacidad',
      'covid',
      'jubilacion'
    ]);
  }

  const funcionMostrarModal = () => {
    let mostrar = false;
    (Object.values(formik.values.datosDocumentos)).forEach((el) => {
      if (el !== null) { mostrar = true; }
    });
    return mostrar;
  }

  const evaluarRetorno = () => {
    if (funcionMostrarModal()) {
      modalDeclinarProceso(declinar)
    }
    else {
      localStorage.setItem('page', parseInt(page) - 1);
      setearPagina((currentPage) => currentPage - 1);
    }
  }

  const declinarSolicitud = async (codPosicion) => {
    const { fechaIngreso, cargo } = formik.values.datosLaborales;
    const resp = await post('desbloqueoposicion', { codPosicion: JSON.parse(cargo).MOES_COD_POSICION, fechaInicio: fechaIngreso });

    if (resp.data.status === 'info') {
      mensajeSimple('error', 'La posición no pudo ser liberada.', 2500);
    }
    else if (resp.data.status === 'success') {
      mensajeSimple(resp.data.status, resp.data.message, 5000);
      setTimeout(() => {
        localStorage.setItem('page', 0);
        setearPagina(0);
        if (page !== 1) { navigate('/consulta-rut'); }
      }, 5000);
    }
    else { mensajeSimple('error', 'Ha ocurrido un error.'); }
  }

  return (
    <>
      <Modal show={visibleModal}>
        <HtmlList lista={listaGuardado} />

        {visibleSpinner && <SpinnerModal txt={txtSpinner} />}

        {!visibleSpinner &&
          <>
            <div className="text-center">
              <button
                className="btnArcoprime mb-4 mt-2"
                onClick={() => {
                  setVisibleSpinner(!visibleSpinner);
                  setVisibleModal(!visibleModal);
                  window.location = "/";
                }}
              >Entendido</button>
            </div>
          </>
        }
      </Modal>
      <div className="form mx-5">
        <div className="form-container mx-5">
          <div className="header">
            <div className="progress my-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                  width:
                    page === 0
                      ? "0%"
                      : page === 1
                        ? "20%"
                        : page === 2
                          ? "40%"
                          : page === 3
                            ? "60%"
                            : page === 4
                              ? "80%"
                              : "100%",
                }}
              ></div>
            </div>
            <div className=" d-flex justify-content-between align-items-baseline">
              <div>
                <h1 className="mb-0"> {FormTitles[page]}{" "} </h1>
                <span className="mt-0" style={{ weight: '700' }}>
                  Campos requeridos poseen este símbolo (*)
                </span>
              </div>

            </div>
          </div>

          <div className="body">{PageDisplay()} </div>
          <div className="footer mb-5">
            <div className="float-end">
              {((page < FormTitles.length - 1) && (page > 0)) &&
                <button className="btn btn-success mt-4 me-2" type="button"
                  onClick={() => { guardarProcesoDeContratacion(formik.values) }}
                >Guardado temporal</button>
              }
              {(bloqueado && page !== 0) &&
                <button
                  className="btn btn-danger align-items-center mt-4 me-5 candadoPosicion"
                  onClick={() => {
                    const { cargo } = formik.values.datosLaborales;
                    modalDeclinarSolicitud(
                      declinarSolicitud,
                      JSON.parse(cargo).MOES_COD_POSICION,
                      `¿Está seguro de liberar la posición? Se perderá el proceso actual y la posición <b>${JSON.parse(cargo).MOES_COD_POSICION} - ${JSON.parse(cargo).MOES_DESCRIPCION_POSICION}</b> estará disponible nuevamente.`);
                  }}
                >
                  Declinar solicitud
                </button>
              }
              <button
                className="btn btn-outline-primary mt-4 me-2"
                onClick={() => {
                  if (page === 0) { navigate('/consulta-rut') }
                  else if (page === 1 && bloqueado) {
                    const { cargo } = formik.values.datosLaborales;
                    modalDeclinarSolicitud(
                      declinarSolicitud,
                      JSON.parse(cargo).MOES_COD_POSICION,
                      txtMensajeDatosIngresados
                    );
                  }
                  else if (page === 5) { evaluarRetorno(); }
                  else {
                    localStorage.setItem('page', parseInt(page) - 1);
                    setearPagina((currentPage) => currentPage - 1);
                  }
                }}
              >Ir atrás</button>
              <button
              id="btnContinuarContratacion"
                disabled={disabledButtonNext()}
                className={page === FormTitles.length - 1 ? `btn btn-success mt-4 ` : `btn btn-primary mt-4 `}
                onClick={() => {
                  if (page === FormTitles.length - 1) {
                    Swal.fire({
                      title: "¿Deseas finalizar el proceso?",
                      showCancelButton: true,
                      cancelButtonText: "Cancelar",
                      confirmButtonText: "Finalizar",
                      reverseButtons: false, // orden botones al reves
                      customClass: {
                        container: 'containerMensajeSimple py-3',
                        text: 'txtLabellInput',
                        popup: `popUpSweetCancelarEnviar`,
                        confirmButton: 'btnCopec me-4',
                        cancelButton: 'btnAtras',
                      },
                      buttonsStyling: false,
                      backdrop: BACKDROP,
                    }).then((result) => {
                      if (result.isConfirmed) { formik.handleSubmit(); }
                      else { Swal.close(); }
                    });
                  } else {
                    if (page === 0) {
                      const txt = `¿Está seguro que el cargo y el plan de horario seleccionados son correctos?
                                    Si procede con el proceso de contratación los campos anteriores no serán editables`;
                      modalEjecutar(avanzar, txt, null);
                    }
                    else {
                      localStorage.setItem('page', parseInt(page) + 1);
                      setearPagina((currentPage) => currentPage + 1);
                    }
                  }
                }
                }
              >
                {page === FormTitles.length - 1 ? "Enviar a Aprobación" : "Continuar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContratacionScreen;