import React from "react";
import { EditarContratacionScreen } from "../components/EditarContratacionScreen";
import FormContratacionScreen from "../components/FormContratacionScreen";
import ListadoVacantesScreen from "../components/ListadoVacantesScreen";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import DetalleVacanteScreen from "../components/DetalleVacanteScreen";
import ConsultarRutScreen from "../components/ConsultarRutScreen";
import VerVacateScreen from "../components/VerVacateScreen";
import IngresoIntranet from "../components/IngresoIntranet";
import ListadoVacantesAprobadasScreen from "../components/ListadoVacantesAprobadasScreen ";
import { PestaniasReingreso } from "../components/ListadoSolicitudReingreso";

// // Componentes viejos
import { PorEnviarSAP } from "../components/PorEnviarSAP"; // descomantar para usar el componete viejo
import { ResumenSolicitudes } from "../components/ResumenSolicitudes"; // descomantar para usar el componente viejo
import { PestaniasFirmaContrato } from "../components/FirmaContrato";  // descomantar para usar el componente viejo

// // Componentes Nuevos
// // Todas las nuevas vista se estan trabajando en la carpeta 'pages'
// import PorEnviarSAP from "../pages/porEnviarSAP/PorEnviarSAP";
// import ResumenSolicitudes from "../pages/resumenSolicitudes/ResumenSolicitudes";
// import PestaniasFirmaContrato from '../pages/firmaContrato/FirmaContrato';
import Monitor from '../pages/monitor/Index';
import LogTemporis from '../pages/temporis/LogTemporis';
import DetalleLogScreen from '../pages/temporis/VerDetalleLog';
import NotFoundPage from '../pages/errores/PageNotFound/404page';
import UnauthorizedPage from '../pages/errores/Unauthorized/unauthorizedPage';


const AppRoute = () => {


  return (
    <div className="containerfluid px-4 p-0">
      <Routes>
        <Route path="/" element={<ListadoVacantesScreen />} />
        <Route path="/listSolicitudAprobadas" element={<ListadoVacantesAprobadasScreen />} />
        <Route path="/listSolicitudReingreso" element={<PestaniasReingreso />} />
        <Route path="/consulta-rut" element={<ConsultarRutScreen />} />
        <Route path="/formulario-contratacion/:idPersona" element={<FormContratacionScreen />} />
        <Route path="/formulario-contratacion" element={<FormContratacionScreen />} />
        <Route path="detalle-vacante/:idSolicitud" element={<DetalleVacanteScreen />} />
        <Route path="firma-contrato/" element={<PestaniasFirmaContrato />} />
        <Route path="envio-sap/" element={<PorEnviarSAP />} />
        <Route path="vacante/:idSolicitud" element={<VerVacateScreen />} />
        <Route path="editar-vacante/:idSolicitud" element={<EditarContratacionScreen />} />
        <Route path="ingreso-intranet/:token" element={<IngresoIntranet />} />
        <Route path="resumen-solicitudes/" element={<ResumenSolicitudes />}/>
        
        <Route path="monitor/" element={<Monitor />} />
        <Route path="log-temporis/" element={<LogTemporis />} />
        <Route path="detalle-temporis/:id" element={<DetalleLogScreen />} />
        <Route path="page-not-found" element={<NotFoundPage />} />
        <Route path="unauthorized" element={<UnauthorizedPage />} />
      </Routes>
    </div>
  );
};

export default AppRoute;
