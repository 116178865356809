export const getColumnas = (objStyleCol,mostrarPDF, Icono, HtmlValidacion,sumaStatusContrato, BtnReenvio) => ({
    rrhh: [
        {
            name: 'idLote',
            selector: row => row.IdLote,
            width: '130px',
            style: objStyleCol
        },
        {
            name: 'N° Personal',
            selector: row => row.NPersonal,
        },
        {
            name: 'Nombre colaborador',
            sortable: true,
            selector: row => row.Nombre,
            wrap: false,
        },
        {
            name: 'Rut',
            selector: row => row.Rut,
            wrap: true,
        },
        {
            name: 'División',
            selector: row => row.Division,
            wrap: true,
        },
        {
            name: 'Unidad organizativa',
            selector: row => row.TextoUnidadOrganizativa,
            wrap: false,
        },
        {
            name: 'Subdivisión',
            selector: row => row.Subdivision,
            wrap: true,
        },
        {
            name: 'Cargo',
            selector: row => row.Cargo,
            wrap: false,
        },
        {
            name: 'Fecha de ingreso',
            selector: row => row.FechaIngreso.replace(/\./g, '-'),
            wrap: false,
        },
        {
            name: 'Fecha fin de contrato',
            selector: row => row.FechaFinContrato.replace(/\./g, '-'),
            wrap: true,
        },
        {
            name: 'Horario',
            selector: row => row.Phtd,
            wrap: true,
            grow: 1,
        },
        {
            name: 'Analista',
            selector: row => row.UsuarioSapEmisor,
            wrap: true,
            grow: 1,
        },
        {
            name: 'Fecha de emisión',
            selector: row => row.FechaEmisionContrato.replace(/\./g, '-'),
            wrap: true,
            grow: 1,
        },
        {
            name: 'Plantilla utilizada',
            selector: row => row.NombrePlantilla,
            wrap: false,
        },
        {
            name: 'Documento',
            cell: (row) => <button onClick={() => mostrarPDF(row.Binario)} className="btn px-1 btn-danger border-0 btn-sm"><Icono iconName='pdf' /></button>,
            style: {
                display: 'flex', justifyContent: 'center'
            }
        },
    ],
    admin: [
        {
            name: 'Id envío',
            sortable: true,
            selector: row => row.IdLote,
            width: '95px',
            style: objStyleCol
        },
        {
            name: 'N° Personal',
            sortable: true,
            selector: row => row.NPersonal,
            width: '85px',
            style: objStyleCol
        },
        {
            name: 'Nombre colaborador',
            sortable: true,
            selector: row => row.Nombre,
            wrap: true,
            minWidth: '300px',
        },
        {
            name: 'Rut',
            sortable: true,
            selector: row => row.Rut,
            width: '100px',
            style: objStyleCol
        },
        {
            name: 'División',
            sortable: true,
            selector: row => row.Division,
            width: '70px',
            style: objStyleCol
        },
        {
            name: 'Unidad organizativa',
            sortable: true,
            wrap: true,
            selector: row => row.TextoUnidadOrganizativa,
            minWidth: '140px',
        },
        {
            name: 'Subdivisión',
            sortable: true,
            selector: row => row.Subdivision,
            width: '100px',
            style: objStyleCol
        },
        {
            name: 'Observaciones',
            sortable: true,
            selector: row => row.status_contrato[row.status_contrato.length - 1].observaciones,
            width: '150px',
            wrap: true,
            style: objStyleCol
        },
        {
            name: 'Cargo',
            sortable: true,
            selector: row => row.Cargo,
            wrap: true,
            width: '150px',
        },
        {
            name: 'Plantilla',
            sortable: true,
            selector: row => row.NombrePlantilla,
            wrap: true,
            width: '150px',
        },
        {
            name: 'Fecha ingreso',
            sortable: true,
            selector: row => row.FechaIngreso.replace(/\./g, '-'),
            wrap: true,
            width: '90px',
            style: objStyleCol
        },
        {
            name: 'Emisión contrato',
            sortable: true,
            selector: row => row.FechaEmisionContrato.replace(/\./g, '-'),
            wrap: true,
            width: '90px',
            style: objStyleCol
        },
        {
            name: 'Validación jefe remuneraciones',
            sortable: true,
            wrap: true,
            cell: (row) => row.status_contrato[0] ? <HtmlValidacion props={row.status_contrato[0].estado_validador} /> : '',
            width: '140px',
            
        },
        {
            name: "Validación Administrador",
            sortable: true,
            selector: row => row.status_contrato[1] ? <HtmlValidacion props={row.status_contrato[1].estado_validador} /> : '',
            width: '140px',
        },
        {
            name: "Colaborador",
            sortable: true,
            selector: row => row.status_contrato[2] ? <HtmlValidacion props={row.status_contrato[2].estado_validador} /> : '',
            width: '120px',
        },
        {
            name: '% Avance',
            sortable: true,
            selector: row => sumaStatusContrato(row.status_contrato),
            width: '70px',
        },
        {
            name: 'Recordatorio',
            sortable: true,
            selector: row => <BtnReenvio dato={row.reenvio_contrato} id={row.id} />,
            width: '130px',
            style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        },
        
    ]
});

