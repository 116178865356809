import { modalCargando, mensajeSimple } from "./sweetAlerts";
import { useExcel } from "../../hooks/generarExcel";
import { post } from "../../api/apis";


export const TituloTabla = ({ props }) => {
  const { title, objData, url } = props;
  const { generarExcel } = useExcel();

  const ejecutar = async () => {
    try {
      modalCargando('Preparando Excel...');
      const resp = await post(url, objData);
      if (resp.status === 200) {
        const datosTabla = await resp.data.data.map(el => {
          const { payload, resp_clean_temporis } = el;
          delete payload.outId_colaborador;
          delete payload.outMensaje;
          return { ...payload, resp_clean_temporis }
        });
        const titulosTabla = Object.keys(datosTabla[0]);

        //Es bueno colocarlos asi y luego mapearlo
        //se pierde en tiempo decodigo pero se gana en entender mejor que ancho tiene cada columna
        const arr = [
          { name: 'Nombres', width: 10 },
          { name: 'Apellido paterno', width: 10 },
          { name: 'Apellido materno', width: 10 },
          { name: 'Rut', width: 10 },
          { name: 'Fecha nacimiento', width: 10 },
          { name: 'Sexo', width: 10 },
          { name: 'Unidad oragnizativa ID', width: 10 },
          { name: 'Cargo generico ID', width: 7 },
          { name: 'Cargo', width: 12 },
          { name: 'ID planta horario', width: 8 },
          { name: 'Fecha inicio vigencia', width: 10 },
          { name: 'Fecha fin vigencia', width: 10 },
          { name: 'Codigo ERP', width: 10 },
          { name: 'Correo', width: 17 },
          { name: 'Respuesta temporis', width: 32 },
        ]

        await generarExcel({
          arrData: datosTabla,
          arrHeaders: titulosTabla,
          sheetHeadersArr: arr.map(el => el.name),
          sheetColumnWidth: arr.map(el => el.width),
          fileName:'temporisTabla'
        });
      }
      mensajeSimple('success', 'Listo!', 1000);
    } catch (error) {
      mensajeSimple('error', '¡No fué posible generar el excel!', 2500);
    }
  }

  return (
    <div className='d-flex justify-content-between align-items-center'>
      {title}
      {url &&
        <button className='ms-0 pe-2 bg-success my-2 btnCopec btn-pill' style={{width: '140px', height: '35px'}} onClick={ejecutar}>
          Exportar a excel
        </button>
      }
    </div>
  );
}