import { mensajeSimple } from "../components/ui/sweetAlerts";
import { BASE_URL_PRODUCCION } from "../utils/Constants";
const OBTENER_DATOS_RUT_SOLICITANTE_EXITO = "OBTENER_DATOS_RUT_SOLICITANTE_EXITO";
const LOADING = "LOADING"

const dataInicial = {
  dataPersona: {
    rut: "",
    data: {},
    status: ""
  },
  loading: false
}

//reducer
export default function datosPersonaSolicitanteReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true }
    case OBTENER_DATOS_RUT_SOLICITANTE_EXITO:
      return { ...state, dataPersona: action.payload, loading: false }
    default:
      return state;
  }
}

//accion de consumir api
const BASE_URL = BASE_URL_PRODUCCION;
const params = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
}

export const obtenerDatosPersonaSolicitanteAction = (rutCompleto, idSolicitud = false) => async (dispatch, getState) => {
  dispatch({ type: LOADING })

  try {
    const url = `${BASE_URL}/verificarpostulante?RutPersona=${rutCompleto}`;
    const response = await fetch(url, params);
    if (response.status === 500 || response.status === 401) { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

    const responseJSON = await response.json();

    if (responseJSON.status === "info") { mensajeSimple(responseJSON.status, responseJSON.message, 2500); }

    if (!responseJSON.data) { return false; }

    dispatch({
      type: OBTENER_DATOS_RUT_SOLICITANTE_EXITO,
      payload: {
        rut: rutCompleto,
        data: responseJSON.data,
        idSolicitud: idSolicitud,
        status: responseJSON.status
      }
    })

    return true
  } catch (error) {
    console.log("error :", error)
    return false
  }
}