// Llamadas a API: Funciones para interactuar con el backend
import { get, post } from '../../api/apis';
// React Core
import { useEffect, useState } from 'react';
// Componentes externos
import DataTable from 'react-data-table-component';
import { customStyles, objStyleCol } from '../../utils/Constants';
// Componentes UI y utilidades
// import { BtnDescargarExcel } from '../../components/BtnDescargarExcel';
import { mensajeSimple } from '../../components/ui/sweetAlerts';
// import { momentFecha } from "../../utils/funciones";
import Spinner from '../../components/ui/Spinner';
import { getColumnas } from './tableColumns';

import { paginationComponentOptions } from './utils';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { TituloTabla } from '../../components/ui/tituloDataTable';
import { capitalizeFirstLetter } from '../../utils/funciones';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icono } from '../../components/ui/Iconos';
// exportToExcel


import DetalleLogScreen from './VerDetalleLog';







// Componente de carta de Bootstrap
const SearchComponent = ({ admins, setData, setLoading, setTotalRows, formik }) => {

    // Función para obtener los logs segun filtro
    const CleanSearch = async () => {
        setLoading(true);
        formik.setValues({
            user_creator: '',
            status: '',
            created_at: ''
        });
        const resp = await post('temporislog', null, formik.values);

        if (resp.data.status === 'error') { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

        if (resp.data.status === 'info') {
            mensajeSimple(resp.data.status, resp.data.message, 2000);
        }
        else {
            setData(resp.data.data);
            setTotalRows(resp.data.total);
            setLoading(false);
        }
    }


    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Buscar</h5>
                        <div className="row">
                            <div className="col-6">
                                <form onSubmit={formik.handleSubmit} id='formSearchAdmin'>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="admins">Administrador</label>

                                                <select
                                                    className="form-select"
                                                    name="user_creator"
                                                    id='admins'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.user_creator}
                                                >

                                                    <option value="">Seleccione</option>
                                                    {admins.map((admin) => (
                                                        <option key={admin['id']} value={admin['id']}>
                                                            {capitalizeFirstLetter(admin['nombre'])}
                                                        </option>
                                                    ))}


                                                </select>
                                            </div>

                                            <div className="form-group mt-2">
                                                <label htmlFor="status">Estado</label>
                                                <select
                                                    className="form-select"
                                                    name="status"
                                                    id='status'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.status}
                                                >

                                                    <option value="">Seleccione</option>
                                                    <option value={1}>Exitoso</option>
                                                    <option value={2}>Fallido</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="created_at">Fecha de creación </label>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id="button-tooltip">Se mostrarán logs con fecha de creación indicada y/o posterior</Tooltip>
                                                    }>
                                                    

                                                    <span style={{cursor: 'pointer', marginLeft: '2px'}}>
                                                        <Icono iconName='info' />
                                                    </span>


                                                </OverlayTrigger>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="created_at"
                                                    name="created_at"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.created_at}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        type='submit'
                                        className="ms-0 pe-2 mt-4 btnCopec btn-pill"
                                    >Buscar</button>

                                    <button
                                        className="ms-4 pe-2 mt-4 btnCopec btn-pill"
                                        style={{ backgroundColor: '#d5d5d5', color: '#000' }}
                                        onClick={() => { CleanSearch(); }}
                                    >Deshacer filtrado</button>


                                </form>
                            </div>
                            <div className="col-6">
                                <div className='alert alert-info' role='alert'>
                                    <p>En esta sección se muestran los registros de los procesos enviados a Temporis. Permitiendo buscar por:</p>
                                    <ul>
                                        <li>Administrador que genera la solicitud</li>
                                        <li>Estado de la respuesta</li>
                                        <li>Fecha de creación</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const LogTemporis = () => {

    const [admins, setAdmins] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [pageTable, setPageTable] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const navigate = useNavigate();
    const [viewData, setViewData] = useState(0);
    const [validateView, setvalidateView] = useState(1);
    const [logData, setLogData] = useState({});

    const verDetalleLog = (logData) => {
        setViewData(1);
        setLogData(logData);
    };


    const formik = useFormik({
        initialValues: {
            user_creator: '',
            status: '',
            created_at: ''
        },
        onSubmit: values => {
            SearchLogs(values);
        }
    });

    const SearchLogs = async (values) => {
        setLoading(true);
        const resp = await post('temporislog', null, values);

        if (resp.data.status === 'error') { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

        if (resp.data.status === 'info') {
            mensajeSimple(resp.data.status, resp.data.message, 2000);
        }
        else {
            setData(resp.data.data);
            setTotalRows(resp.data.total);
            setLoading(false);
            setPageTable(1);
        }
    }



    // Función para obtener datos de la página Monitor
    const llamarPagina = async (pagina = 1, totalporpagina = 10) => {
        setLoading(true);
        const body = {
            user_creator: formik.values.user_creator,
            status: formik.values.status,
            created_at: formik.values.created_at,
            page: pagina,
            per_page: totalporpagina
        }
        const resp = await post('temporislog', null, body);


        if (resp === 404) {
            // mensajeSimple('error', resp.data.message, 3000);
            navigate('/page-not-found');
        }

        if (resp === 403) {
            navigate('/unauthorized');
        }
        else {
            setData(resp.data.data);
            setTotalRows(resp.data.total);
            setLoading(false);
            setPageTable(pagina);
            AdminsOptions();
            setvalidateView(0);
            // setPageTable(totalporpagina);
        }
    }

    // Función para obtener datos de la página Monitor
    const AdminsOptions = async () => {
        const resp = await get('administradorestemporis', null, null);


        // setLoading(false);

        if (resp.data.status === 'error') { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

        if (resp.data.status === 'info') {
            mensajeSimple(resp.data.status, resp.data.message, 2000);
        }
        else {
            setAdmins(resp.data.data);
        }
    }

    useEffect(() => {
        llamarPagina();
    }, []);


    const TableLog = ({ data, formik, verDetalleLog }) => {



        // Definición de columnas para la tabla
        const columnas = getColumnas(objStyleCol, verDetalleLog);



        // Funciones para manejar la paginación
        const handlePageChange = page => {
            setPageTable(page);
            llamarPagina(page, perPage);
        };

        const handlePerRowsChange = async (newPerPage, page) => {
            setLoading(true);
            setPerPage(newPerPage);
            llamarPagina(page, newPerPage);
        };

        return (
            <div className="row mb-4">
                <div className="col-12">
                    <div className="card cardPestania">
                        {
                            loading
                                ? (<Spinner />)
                                : (
                                    <div className="card-body contenedorTabla">
                                        {data.length > 0
                                            ?
                                            (
                                                <DataTable
                                                    // Quiero que mi titulo tenga un boton para exportar a excel al final de la fila
                                                    // deseo concedido (n_n)
                                                    title={
                                                        <TituloTabla
                                                            props={{
                                                                title: 'Listar registros de temporis',
                                                                objData: formik.values,
                                                                url: 'temporislogexcel'
                                                            }}
                                                        />}
                                                    responsive={true}
                                                    columns={columnas.temporis}
                                                    data={data}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationDefaultPage={pageTable}
                                                    paginationPerPage={perPage}
                                                    paginationTotalRows={totalRows}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    customStyles={customStyles}
                                                    dense
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="450px"
                                                // sortable={true}
                                                />
                                            )
                                            :
                                            <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                                        }

                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        );
    }

    return (

        <div className='row'>
            <div className="col-12 mb-3">
                {validateView ? '' : <SearchComponent admins={admins} setData={setData} setLoading={setLoading} setTotalRows={setTotalRows} formik={formik} />}
            </div>

            <div className="col-12 mt-3">
                {!viewData ? <TableLog data={data} formik={formik} verDetalleLog={verDetalleLog} /> : <DetalleLogScreen logData={logData} setViewData={setViewData} />}
            </div>
        </div>
    );
}

export default LogTemporis;