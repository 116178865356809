
import { mensajeSimple, modalAprobarRechazarContrato, modalCargando, ModalEnviandoSAP, ModalEnviandoContratos } from './ui/sweetAlerts';
import { paginationComponentOptions, objStyleCol } from '../utils/Constants';
import { devolverPlural, sumaStatusContrato } from '../utils/funciones';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { get, getUno, post } from '../api/apis';
import { Icono } from './ui/Iconos';
import Spinner from './ui/Spinner';
import jwtDecode from 'jwt-decode';

export function PestaniasFirmaContrato() {
  const [visible, setVisible] = useState(0);
  return (
    <Tabs
      defaultActiveKey="home"
      transition={false}
      id="noanim-tab-example"
      className="mb-0"
    >
      <Tab eventKey="home" title="Documentos a revisar">
        <MisContratos />
      </Tab>
      {(localStorage.getItem('id_perfil') === '3') &&
        <Tab eventKey="profile" title="Monitor">
          <Historial />
        </Tab>
      }
    </Tabs>
  );
}




const resolucionContrato = async (obj) => {
  modalCargando(`${obj.respuesta === '1' ? "Aprobando " : "Rechazando "}contrato${devolverPlural(obj)}...`);
  const data = { ...obj, idPersona: jwtDecode(localStorage.getItem('token')).id_usuario_sap }

  const resp = await post('revisarcontrato', {}, data);

  if (resp.data && resp.data.status === 'success') {
    mensajeSimple(
      resp.data.status,
      `Contrato${devolverPlural(obj)} ${data.respuesta === '1' ? 'aprobado' : 'rechazado'}${devolverPlural(obj)} con éxito.`,
      4000
    );


  }
  else {
    mensajeSimple('error', 'Ha ocurrido un problema!');
  }
}

const traerColor = (estado) => {
  let color = '';
  switch (estado) {
    case 'Aprobado':
      color = 'success'
      break;
    case 'Rechazado':
      color = 'orange'
      break;
    case 'En Espera':
      color = 'warning'
      break;
    default:
      color = 'transparent'
      break;
  }
  return color;
}

const HtmlValidacion = ({ props }) => {
  return (
    <>
      <span>{props.nombre}</span>
      <div
        className={`bg-${traerColor(props.nombre)} position-absolute`}
        style={{
          height: '25px',
          width: '25px',
          borderRadius: '50%',
          right: '3px',
          top: '3px',
        }}
      >
      </div>
    </>
  )
}

export const Historial = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [contratosArray, setContratosArray] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageTable, setPageTable] = useState(1);
  const [dataMonitor, setDataMonitor] = useState([]);

  const reenviarContrato = async (id) => {
    modalCargando('Reenviando');
    const resp = await post('reenvio_contrato', { id_contrato: id })
    console.log({ resp });

    if (resp.data.status === 'info') { mensajeSimple('info', resp.data.message, 4500); }
    else if (resp.data.status === 'success') {
      mensajeSimple(resp.data.status, resp.data.message, 4000);
      llamarPagina()
      // setTimeout(() => {
      //   localStorage.setItem('page', parseInt(page) + 1);
      //   setearPagina((currentPage) => currentPage + 1);
      //   setBloqueado(!bloqueado);
      // }, 5000);
    }
    else { mensajeSimple('error', 'Ha ocurrido un error.'); }
  }

  const BtnReenvio = ({dato, id}) => {
    return (
      <>
        {dato === '1'
          ? <button onClick={() => { reenviarContrato(id) }} className='btn btn-primary btn-sm'>Reenviar</button>
          : null
        }
      </>
    )
  }

  const columnas = {
    rrhh: [
      {
        name: 'idLote',
        selector: row => row.IdLote,
        width: '130px',
        style: objStyleCol
      },
      {
        name: 'N° Personal',
        // sortable: false,
        selector: row => row.NPersonal,
      },
      {
        name: 'Nombre colaborador',
        sortable: true,
        selector: row => row.Nombre,
        wrap: false,
      },
      {
        name: 'Rut',
        // sortable: false,
        selector: row => row.Rut,
        wrap: true,
      },
      {
        name: 'División',
        // sortable: false,
        selector: row => row.Division,
        wrap: true,
      },
      {
        name: 'Unidad organizativa',
        // sortable: false,
        selector: row => row.TextoUnidadOrganizativa,
        wrap: false,
      },
      {
        name: 'Subdivisión',
        // sortable: false,
        selector: row => row.Subdivision,
        wrap: true,
      },
      {
        name: 'Cargo',
        // sortable: false,
        selector: row => row.Cargo,
        wrap: false,
      },
      {
        name: 'Fecha de ingreso',
        // sortable: false,
        selector: row => row.FechaIngreso.replace(/\./g, '-'),
        wrap: false,
      },
      {
        name: 'Fecha fin de contrato',
        // sortable: false,
        selector: row => row.FechaFinContrato.replace(/\./g, '-'),
        wrap: true,
      },
      {
        name: 'Horario',
        // sortable: false,
        selector: row => row.Phtd,
        wrap: true,
        grow: 1,
      },
      {
        name: 'Analista',
        // sortable: false,
        selector: row => row.UsuarioSapEmisor,
        wrap: true,
        grow: 1,
      },
      {
        name: 'Fecha de emisión',
        // sortable: false,
        selector: row => row.FechaEmisionContrato.replace(/\./g, '-'),
        wrap: true,
        grow: 1,
      },
      {
        name: 'Plantilla utilizada',
        // sortable: false,
        selector: row => row.NombrePlantilla,
        wrap: false,
      },
      {
        name: 'Documento',
        cell: (row) => <button onClick={() => mostrarPDF(row.Binario)} className="btn px-1 btn-danger border-0 btn-sm"><Icono iconName='pdf' /></button>,
        style: {
          display: 'flex', justifyContent: 'center'
        }
      },
    ],
    admin: [
      {
        name: 'Id envío',
        sortable: false,
        selector: row => row.IdLote,
        width: '95px',
        style: objStyleCol
      },
      {
        name: 'N° Personal',
        sortable: false,
        selector: row => row.NPersonal,
        width: '85px',
        style: objStyleCol
      },
      {
        name: 'Nombre colaborador',
        sortable: false,
        selector: row => row.Nombre,
        wrap: true,
        minWidth: '300px',
      },
      {
        name: 'Rut',
        sortable: false,
        selector: row => row.Rut,
        width: '100px',
        style: objStyleCol
      },
      {
        name: 'División',
        sortable: false,
        selector: row => row.Division,
        width: '70px',
        style: objStyleCol
      },
      {
        name: 'Unidad organizativa',
        sortable: false,
        wrap: true,
        selector: row => row.TextoUnidadOrganizativa,
        minWidth: '140px',
      },
      {
        name: 'Subdivisión',
        sortable: false,
        selector: row => row.Subdivision,
        width: '100px',
        style: objStyleCol
      },
      {
        name: 'Observaciones',
        sortable: false,
        selector: row => row.status_contrato[row.status_contrato.length - 1].observaciones,
        width: '150px',
        wrap: true,
        style: objStyleCol
      },
      {
        name: 'Cargo',
        sortable: false,
        selector: row => row.Cargo,
        wrap: true,
        width: '150px',
      },
      {
        name: 'Plantilla',
        sortable: false,
        selector: row => row.NombrePlantilla,
        wrap: true,
        width: '150px',
      },
      {
        name: 'Fecha ingreso',
        selector: row => row.FechaIngreso.replace(/\./g, '-'),
        wrap: true,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Emisión contrato',
        selector: row => row.FechaEmisionContrato.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Validación jefe remuneraciones',
        wrap: true,
        cell: (row) => row.status_contrato[0] ? <HtmlValidacion props={row.status_contrato[0].estado_validador} /> : '',
        width: '140px',
      },
      {
        name: "Validación Administrador",
        sortable: true,
        selector: row => row.status_contrato[1] ? <HtmlValidacion props={row.status_contrato[1].estado_validador} /> : '',
        width: '140px',
      },
      {
        name: "Colaborador",
        selector: row => row.status_contrato[2] ? <HtmlValidacion props={row.status_contrato[2].estado_validador} /> : '',
        width: '120px',
      },
      {
        name: '% Avance',
        sortable: false,
        selector: row => sumaStatusContrato(row.status_contrato),
        width: '70px',
      },
      {
        name: 'Recordatorio',
        sortable: false,
        selector: row => <BtnReenvio dato={row.reenvio_contrato} id={row.id} />,
        width: '130px',
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },
    ]
  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const seleccionarContrato = (obj) => {

    setContratosArray(obj.selectedRows);
  }

  const llamarPagina = async () => {
    const resp = await get('monitor', null, null);
    const resp2 = await getUno('monitorcentral', null, null);
    setDataMonitor(resp2.data.data);
    setLoading(false);

    if (resp === 500) { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

    if (resp.data.status === 'info') {
      mensajeSimple(resp.data.status, resp.data.message, 2000);
    }
    else if (resp.data.success) {
      setData(resp.data.data);
    }


    
  }

  const mostrarPDF = (strBase64) => {
    const byteString = window.atob(strBase64);
    const int8Array = new Uint8Array(new ArrayBuffer(byteString.length));
    for (let i = 0; i < byteString.length; i++) { int8Array[i] = byteString.charCodeAt(i); }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    window.open(URL.createObjectURL(blob), '_blank');
  }

  const handlePageChange = page => {
    setPageTable(page);
    llamarPagina(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    llamarPagina(page);
  };

  useEffect(() => { llamarPagina(); }, []);

  const customStyles = {
    tableBody: {
      style: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    header: {
      style: {
        minHeight: '40px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
        marginLeft: '2px',
        marginRight: '2px',
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
        marginLeft: '2px',
        marginRight: '2px',
      },
    },
    responsiveWrapper: {
      style: {
        border: `1px solid ${defaultThemes.default.divider.default}`,
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 ">
          <div className="card cardPestania">
            {
              loading
                ? (<Spinner />)
                : (
                  <div className="card-body contenedorTabla">
                    {dataMonitor.length > 0
                      ?
                      (
                        <DataTable
                          title={false}
                          responsive={true}
                          columns={columnas.admin}
                          data={dataMonitor}
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationDefaultPage={pageTable}
                          paginationPerPage={perPage}
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          paginationComponentOptions={paginationComponentOptions}
                          customStyles={customStyles}
                          onSelectedRowsChange={seleccionarContrato}
                          dense
                          fixedHeader
                          fixedHeaderScrollHeight="450px"
                        />
                      )
                      :
                      <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                    }
                  </div>
                )
            }
          </div>
        </div>
      </div>
      {/* <div className="row mt-3">
        <div className="col-sm-12">
          {contratosArray.length > 0 &&
            <button onClick={() => modalAprobarRechazarContrato(contratosArray, resolucionContrato)} className="ms-0 pe-2 btnCopec">
              Revisar contrato{contratosArray.length > 1 ? 's' : ''}
            </button>
          }
        </div>
      </div> */}
    </>
  )
}

export const MisContratos = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [contratosArray, setContratosArray] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageTable, setPageTable] = useState(1);
  const [count, setCount] = useState(0);
  const [contratoName, setContratoNAme] = useState('');
  const [infoContrato, setInfoContrato] = useState('');
  const [visibleModal, setVisibleModal] = useState(false)
  const [lenght, setLenght] = useState(0)



  const columnas = {
    rrhh: [
      {
        name: 'ID Lote',
        sortable: false,
        selector: row => row.IdLote,
        width: '95px',
        style: objStyleCol
      },
      {
        name: 'N° Personal',
        sortable: false,
        selector: row => row.NPersonal,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Nombre colaborador',
        sortable: false,
        selector: row => row.Nombre,
        wrap: true,
        width: '330px',
      },
      {
        name: 'Rut',
        sortable: false,
        selector: row => row.Rut,
        width: '98px',
        style: objStyleCol
      },
      {
        name: 'División',
        sortable: false,
        selector: row => row.Division,
        width: '80px',
        style: objStyleCol
      },
      {
        name: 'Unidad organizativa',
        sortable: false,
        selector: row => row.TextoUnidadOrganizativa,
        wrap: true,
        width: '300px',
      },
      {
        name: 'Subdivisión',
        sortable: false,
        selector: row => row.Subdivision,
        wrap: true,
        width: '100px',
        style: objStyleCol
      },
      {
        name: 'Cargo',
        sortable: false,
        selector: row => row.Cargo,
        wrap: true,
        width: '250px',
      },
      {
        name: 'Fecha ingreso',
        sortable: false,
        selector: row => row.FechaIngreso.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Fecha Fin Contrato',
        sortable: false,
        selector: row => row.FechaFinContrato.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Horario',
        sortable: false,
        selector: row => row.Phtd,
        wrap: true,
        width: '90px',
      },
      {
        name: 'Analista',
        sortable: false,
        selector: row => row.UsuarioSapEmisor,
        wrap: true,
        width: '140px',
      },
      {
        name: 'Fecha de Emisión',
        sortable: false,
        selector: row => row.FechaEmisionContrato.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Plantilla Utilizada',
        sortable: false,
        selector: row => row.NombrePlantilla,
        wrap: true,
        width: '250px',
      },
      {
        name: 'Documento',
        cell: (row) => <button onClick={() => mostrarPDF(row.Binario)} className="btn px-1 btn-danger border-0 btn-sm"><Icono iconName='pdf' /></button>,
        style: {
          display: 'flex', justifyContent: 'center'
        }
      },
    ],
    admin: [
      {
        name: 'N° Personal',
        sortable: false,
        selector: row => row.NPersonal,
        width: '85px',
        style: objStyleCol
      },
      {
        name: 'Nombre colaborador',
        sortable: false,
        selector: row => row.Nombre,
        wrap: true,
        minWidth: '300px',
      },
      {
        name: 'Rut',
        sortable: false,
        selector: row => row.Rut,
        width: '100px',
        style: objStyleCol
      },
      {
        name: 'Unidad organizativa',
        sortable: false,
        wrap: true,
        selector: row => row.TextoUnidadOrganizativa,
        minWidth: '140px',
      },
      {
        name: 'Cargo',
        sortable: false,
        selector: row => row.Cargo,
        wrap: true,
        width: '150px',
      },
      {
        name: 'Fecha Ingreso',
        selector: row => row.FechaIngreso.replace(/\./g, '-'),
        wrap: true,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Fecha Fin Contrato',
        selector: row => row.FechaFinContrato.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Documento',
        cell: (row) => <button onClick={() => mostrarPDF(row.Binario)} className="btn px-1 btn-danger border-0 btn-sm"><Icono iconName='pdf' /></button>,
        style: {
          display: 'flex', justifyContent: 'center'
        }
      },
    ]
  }

  const seleccionarContrato = (obj) => {
    console.log(obj)

    setContratosArray(obj.selectedRows);
    console.log(contratosArray);
  }

  const borrarChecks = () => {
    if (!document.getElementsByName('select-all-rows')[0]?.checked) {
      document.getElementsByName('select-all-rows')[0]?.click();
    }
    document.getElementsByName('select-all-rows')[0]?.click();
    setContratosArray([])
  }

  const llamarPagina = async () => {
    borrarChecks();
    const resp = await get('monitor', null, null);
    setLoading(false);

    if (resp === 500) { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

    if (resp.data.status === 'info') {
      setData([]);
      mensajeSimple(resp.data.status, resp.data.message, 2000);
    }
    else if (resp.data.success) {
      setData(resp.data.data);
    }

  }

  const getPerfil = (idPerfil, columnas) => {
    if (idPerfil === '3') { return columnas.rrhh; }
    if (idPerfil === '4') { return columnas.admin; }
    else { return columnas.admin; }
  }

  const mostrarPDF = (strBase64) => {
    const byteString = window.atob(strBase64);
    const int8Array = new Uint8Array(new ArrayBuffer(byteString.length));
    for (let i = 0; i < byteString.length; i++) { int8Array[i] = byteString.charCodeAt(i); }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    window.open(URL.createObjectURL(blob), '_blank');
  }

  const handlePageChange = page => {
    setPageTable(page);
    llamarPagina(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    llamarPagina(page);
  };

  const resolucionContrato = async (obj) => {
    console.log(obj);

    setVisibleModal(true)
    setLenght(obj.idContrato.length);
    let contador = 1;

    for (const item of obj.idContrato) {

      obj['idContrato'] = item.id

      const data = { ...obj, idPersona: jwtDecode(localStorage.getItem('token')).id_usuario_sap }
      const resp = await post('revisarcontrato', {}, data);
      setContratoNAme(() => setContratoNAme(item.nombre))
      setInfoContrato(() => setInfoContrato(resp.data.message))
      if (resp === (502)) {
        setVisibleModal(false);
        mensajeSimple(resp.data.status, resp.data.message, 2000);
        break;
      }
      setCount(() => setCount(contador++));

      llamarPagina()
    }
    setTimeout(() => {
      setVisibleModal(false)
      setCount(() => setCount(0));
    }, 3000);
    setTimeout(() => {
      mensajeSimple('success', "Solicitud(es) Actualizada(s)", 4000);
      setContratoNAme(() => setContratoNAme(''))
      setInfoContrato(() => setInfoContrato(''))
    }, 4000);


  }

  useEffect(() => { llamarPagina(); }, []);

  const customStyles = {
    tableBody: {
      style: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    header: {
      style: {
        minHeight: '40px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
        marginLeft: '2px',
        marginRight: '2px',
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
        marginLeft: '2px',
        marginRight: '2px',
      },
    },
    responsiveWrapper: {
      style: {
        border: `1px solid ${defaultThemes.default.divider.default}`,
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 ">
          <div className="card cardPestania">

            <>
              <ModalEnviandoContratos actual={count} max={lenght} contratoName={contratoName} infoContrato={infoContrato} visibleModal={visibleModal} />
              {
                loading
                  ? (<Spinner />)
                  : (
                    <div className="card-body contenedorTabla">
                      {data.length > 0
                        ?
                        (
                          <DataTable
                            title={false}
                            responsive={true}
                            columns={getPerfil(localStorage.getItem('id_perfil'), columnas)}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationDefaultPage={pageTable}
                            paginationPerPage={perPage}
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationComponentOptions={paginationComponentOptions}
                            selectableRows
                            customStyles={customStyles}
                            onSelectedRowsChange={seleccionarContrato}
                            dense
                            fixedHeader
                            fixedHeaderScrollHeight="440px"
                          />

                        )
                        :
                        <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                      }
                    </div>
                  )
              }
            </>
          </div>
        </div>
      </div>
      {contratosArray.length > 0 &&
        <div className="row mt-3">
          <div className="col-sm-12">
            <button onClick={() => modalAprobarRechazarContrato(contratosArray, resolucionContrato)} className="ms-0 pe-2 btnCopec">
              Revisar contrato{contratosArray.length > 1 ? 's' : ''}
            </button>
          </div>
        </div>
      }
    </>
  )
}