export const traerColor = (estado) => {
    let color = '';
    switch (estado) {
        case 'Aprobado':
            color = 'success'
            break;
        case 'Rechazado':
            color = 'orange'
            break;
        case 'En Espera':
            color = 'warning'
            break;
        default:
            color = 'transparent'
            break;
    }
    return color;
}

export const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

export const mostrarPDF = (strBase64) => {
    const byteString = window.atob(strBase64);
    const int8Array = new Uint8Array(new ArrayBuffer(byteString.length));
    for (let i = 0; i < byteString.length; i++) { int8Array[i] = byteString.charCodeAt(i); }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    window.open(URL.createObjectURL(blob), '_blank');
}

// Calcula el porcenje de avnace de un contrato
export const calcularPorcentajeAvance = (status_contrato) => {
    let porcentajeAvance = 0;
    let aprobados = 0;

    for (let i = 0; i < status_contrato.length; i++) {
        const estado = status_contrato[i]?.estado_validador?.nombre || 'N/A';
        
        if (estado === 'Aprobado') {
            aprobados++;
        } else if (estado === 'Rechazado') {
            break;
        }
    }

    porcentajeAvance = (aprobados / 3) * 100;

    if (aprobados === 3) {
        porcentajeAvance = 100;
    }

    return porcentajeAvance.toFixed(2) + '%';
};


