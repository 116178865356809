// dataTablesStyles.js
import { defaultThemes } from 'react-data-table-component';

export const customStyles = {
    tableBody: {
        style: {
            borderLeftStyle: 'solid',
            borderLeftWidth: '1px',
            borderLeftColor: defaultThemes.default.divider.default,
        },
    },
    header: {
        style: {
            minHeight: '40px',
        },
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: defaultThemes.default.divider.default,
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
            },
            marginLeft: '2px',
            marginRight: '2px',
        },
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
            },
            marginLeft: '2px',
            marginRight: '2px',
        },
    },
    responsiveWrapper: {
        style: {
            border: `1px solid ${defaultThemes.default.divider.default}`,
        }
    }
};
